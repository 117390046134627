import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Project } from '../_interfaces/project';
import { Token } from '../_interfaces/token';

@Injectable({
    providedIn: 'root'
})
export class GlobalsProvider {

    constructor() { }

    /*
    /* IMPORTANT : Deployment environments
    */
    private production: boolean = true;
    private dev: boolean = false;

    private testnetOpenseaUrl = "https://testnets.opensea.io/assets";
    private mainnetOpenseaUrl = "https://opensea.io/assets";

    private testnetCampfireUrl = "https://campfire.exchange/";
    private mainnetCampfireUrl = "https://campfire.exchange/";

    private mainnetLooksRareUrl = "https://looksrare.org/collections/";

    private mainnetAppBaseUrl = "https://app.ntent.art/n/";
    private testnetAppBaseUrl = "https://dev.app.ntent.art/n/";

    public preferredIpfsGatewayBaseUrl = "ipfs.nftstorage.link";

    /*
    /* Addresses
    */

    public teamWallet = "0x5772A94eE0304325626371c8701a0dA825bfdF04";
    public infuraProjectId = "7177842209804a36ac212fc4fb3850aa";

    private testNetwork = "kovan";
    private devNetwork = "goerli";
    private devNetworkPolygon = "mumbai";
    private devAlchemyEndpointUrl = `https://eth-${this.devNetwork}.g.alchemy.com/v2/P_ZWE0bXclNGtFqJPfv9AQ7YsLof35UX`
    
    private testAlchemyEndpointUrl = `https://eth-${this.testNetwork}.alchemyapi.io/v2/1PNbHKbJAKb2272av47kSBcluYgMER4M`
    private mainnetAlchemyEndpointUrl = "https://eth-mainnet.alchemyapi.io/v2/DRZjwTHapoNSD6-bg20-zSiMrrbq5WlG";

    private mumbaiPolygonAlchemyEndpointUrl = "https://polygon-mumbai.g.alchemy.com/v2/Sm7hOGNKDtBKeeXKH549-KSpIgbZ7hBN";
    private polygonMainnetAlchemyEndpointUrl = "https://polygon-mainnet.g.alchemy.com/v2/shQH-cHsvus35YnkOYa2VLBgdV-sXMDG";

    private avaDevInfuraEndpoint = "https://avalanche-fuji.infura.io/v3/b36ba0c921da49ae870af626525c08d5";
    private avaMainnetInfuraEndpoint = "https://avalanche-mainnet.infura.io/v3/b36ba0c921da49ae870af626525c08d5";

    /*
    /* TX verifications endpoints
    */

    public devTxBaseUrl = `https://${this.devNetwork}.etherscan.io/tx/`;
    public testTxBaseUrl = `https://${this.devNetwork}.etherscan.io/tx/`;
    public mainnetTxBaseUrl = "https://etherscan.io/tx/";

    public mumbaiPolygonTxBaseUrl = "https://mumbai.polygonscan.com/tx/";
    public polygonTxBaseUrl = "https://polygonscan.com/tx/";

    public avaFujiTxBaseUrl = "https://testnet.snowtrace.io/tx/";
    public avaMainnetTxBaseUrl = "https://snowtrace.io/tx/";

    public contractAddressMapping = [
        { chainCategory: "genesis", chain: "ethereum", chainId : 5, environment: "dev", network: "goerli", address: "0x08e7EcB4D8C34daFFeF1D658B315C6003bB5B167", txBaseVerificationUrl: this.devTxBaseUrl, dataEndpoint: this.devAlchemyEndpointUrl, openseaBaseUrl: 'https://testnets.opensea.io/collection/ntent-art?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        { chainCategory: "genesis", chain: "ethereum", chainId : 5, environment: "test", network: "kovan", address: "0xbCC3ffDc2709127bB725D778C90A383299c19Ae3", txBaseVerificationUrl: this.testTxBaseUrl, dataEndpoint: this.testAlchemyEndpointUrl, openseaBaseUrl: 'https://testnets.opensea.io/collection/ntent-art?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        { chainCategory: "genesis", chain: "ethereum", chainId : 1, environment: "prod", network: "mainnet", address: "0x8Ec319e40E0A91A11cF584053EB5e420C613f01d", txBaseVerificationUrl: this.mainnetTxBaseUrl, dataEndpoint: this.mainnetAlchemyEndpointUrl, openseaBaseUrl: 'https://opensea.io/collection/ntentgenesis?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        { chainCategory: "main", chain: "ethereum", chainId : 5, environment: "dev", network: "goerli", address: "0x0da591D82b7b68389B4C28332a260Dc272657FCE", txBaseVerificationUrl: this.devTxBaseUrl, dataEndpoint: this.devAlchemyEndpointUrl, openseaBaseUrl: 'https://testnets.opensea.io/collection/ntent-art?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        { chainCategory: "main", chain: "ethereum", chainId : 1,  environment: "prod", network: "mainnet", address: "0x0A1F4C550cD7Bc37Af841e81fCf3557269f4E912", txBaseVerificationUrl: this.mainnetTxBaseUrl, dataEndpoint: this.mainnetAlchemyEndpointUrl, openseaBaseUrl: 'https://opensea.io/collection/ntentart?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        { chainCategory: "labs", chain: "polygon", chainId : 80001, environment: "dev", network: "mumbai", address: "0xBFdB7C51F870d07704872208827bf559153C28C7", txBaseVerificationUrl: this.mumbaiPolygonTxBaseUrl, dataEndpoint: this.mumbaiPolygonAlchemyEndpointUrl, openseaBaseUrl: 'https://testnets.opensea.io/collection/ntent-labs?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        { chainCategory: "labs", chain: "polygon", chainId : 137, environment: "prod", network: "mainnet", address: "0xbfdb7c51f870d07704872208827bf559153c28c7", txBaseVerificationUrl: this.polygonTxBaseUrl, dataEndpoint: this.polygonMainnetAlchemyEndpointUrl, openseaBaseUrl: 'https://opensea.io/collection/ntent-labs?search[query]=TKTK&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT' },
        // { chainCategory: "ava", chain: "avalanche", chainId : 43113, environment: "dev", network: "fuji", address: "0xB6aDb7c3E1E3B8e89080c3057a984028c13a592D", txBaseVerificationUrl: this.avaFujiTxBaseUrl, dataEndpoint: this.avaDevInfuraEndpoint, openseaBaseUrl: '' },
        // { chainCategory: "ava", chain: "avalanche", chainId : 43114, environment: "prod", network: "mainnet", address: "0xB6aDb7c3E1E3B8e89080c3057a984028c13a592D", txBaseVerificationUrl: this.avaMainnetTxBaseUrl, dataEndpoint: this.avaMainnetInfuraEndpoint, openseaBaseUrl: '' },
    ]

    public projectConfig = [
        {chainCategory: "genesis", projectId: 1, rule: "NoMaxMint"},
        {chainCategory: "genesis", projectId: 1, rule: "NoPresale"},   
        {chainCategory: "genesis", projectId: 1, rule: "NoClaim"},     
        {chainCategory: "genesis", projectId: 2, rule: "NoMaxMint"},
        {chainCategory: "genesis", projectId: 2, rule: "NoPresale"},

        {chainCategory: "labs", projectId: 2, rule: "NoPresale"},
        {chainCategory: "main", projectId: 3, rule: "NoPresale"},
        {chainCategory: "main", projectId: 5, rule: "NoPresale"},
        {chainCategory: "main", projectId: 9, rule: "NoPresale"},
        {chainCategory: "main", projectId: 6, rule: "NoClaim"},
        {chainCategory: "main", projectId: 8, rule: "NoClaim"},
        {chainCategory: "main", projectId: 5, rule: "NoMaxMint"},
        {chainCategory: "main", projectId: 6, rule: "NoMaxMint"},
        {chainCategory: "main", projectId: 6, rule: "DifferentImageSizes"},
        {chainCategory: "main", projectId: 6, rule: "DetailViewDefault"},
        {chainCategory: "main", projectId: 4, rule: "ClaimOnly"},
        {chainCategory: "main", projectId: 4, rule: "ClaimNoBurn"},
        {chainCategory: "main", projectId: 3, rule: "CssClassAddition", class : "token-iframe-ukraine"},
        {chainCategory: "main", projectId: 7, rule: "ShortProjectName", title : "TPOW"},
        {chainCategory: "main", projectId: 7, rule: "NoPresale", title : "TPOW"},
        {chainCategory: "main", projectId: 8, rule: "ShowNextToken" },
        {chainCategory: "main", projectId: 10, rule: "SoldOutMessage", message : "Minted, not yet listed. Inquire for more info." },
        {chainCategory: "main", projectId: 11, rule: "SoldOutMessage", message : "Minted, not yet listed. Inquire for more info." },
        {chainCategory: "ava", projectId: 1, rule: "NoPresale" },
        // {chainCategory: "main", projectId: 7, rule: "NoClaim"}
    ]

    public articleConfig = [
        {chainCategory: "genesis", projectId: 1, url: "https://www.ntent.art/articles/neovita"},
        {chainCategory: "genesis", projectId: 2, url: "https://www.ntent.art/articles/dissipation"},
        
        {chainCategory: "main", projectId: 3, url: "https://www.ntent.art/articles/fckputin"},
        {chainCategory: "main", projectId: 4, url: "https://www.ntent.art/articles/floressence"},
        {chainCategory: "main", projectId: 5, url: "https://www.ntent.art/articles/collodionprocess"},
        {chainCategory: "main", projectId: 6, url: "https://www.ntent.art/articles/onedropnfts"},
        {chainCategory: "main", projectId: 7, url: "https://www.ntent.art/articles/tpow"},
        {chainCategory: "main", projectId: 8, url: "https://www.ntent.art/articles/momentafter"},
        {chainCategory: "labs", projectId: 2, url: "https://www.ntent.art/articles/fckputin"},

        {chainCategory: "tezos", projectId: 1, url: "https://www.ntent.art/articles/televangelist"},
        {chainCategory: "tezos", projectId: 2, url: "https://www.ntent.art/articles/displacedanger"},
    ]


    private contractCategoryProjectExplicitMapping = [
        { projectId: "1", chainCategory: "genesis" },
        { projectId: "2", chainCategory: "genesis" }
    ]

    public contractChainCategories = [
        { chainCategory: "genesis", sortOrder: 1 },
        { chainCategory: "main", sortOrder: 2 },
        // { chainCategory: "ava", sortOrder: 3 },
        { chainCategory: "labs", sortOrder: 4 }
    ]

    //{category : "labs", projectProvider : pP}
    public contractProjectProviders = [];

    public project: Project = new Project();
    public projects: Project[] = [];
    public collectionTokens: Token[] = [];
    public collectionTokenList = new Subject<any>();
    public tokenBaseUri = "https://hosted.ntent.art/token-metadata/";
    public noWallet = false;
    public projectsLoaded = false;
    public tokensLoaded = false;
    public isMintPage = true;
    public justMinted = false;
    public isMobile = false;

    public nextMainProjectId = 12;

    /*
    /* These properties ensure we are connecting to the appropriate chains, networks, and contract addresses;
    */

    public projectId = "";
    public chain = "ethereum";
    public chainCategory = "";

    tokenContractAddress(chainCategory = null) {

        if (!chainCategory)
            this.chainCategory = this.getDefaultCategoryProjectMapping(this.projectId);
        else
            this.chainCategory = chainCategory;

        var chain = this.getChainFromCategory(chainCategory);
        var environment = this.getEnvironment();

        return this.contractAddressMapping.filter(
            m => m.chainCategory == this.chainCategory &&
                m.chain == chain &&
                m.environment == environment)[0].address;
    }

    tokenContractAddressAll() {
        var environment = this.getEnvironment();
        return this.contractAddressMapping.filter(m =>
            m.environment == environment).map(m => m.address);
    }

    thirdpartyEndpoint() {

        var environment = this.getEnvironment();

        return this.contractAddressMapping.filter(m =>
            m.chain == this.chain &&
            m.environment == environment)[0].dataEndpoint;
    }

    txVerificationEndpoint() {
        var environment = this.getEnvironment();

        return this.contractAddressMapping.filter(m =>
            m.chain == this.chain &&
            m.environment == environment)[0].txBaseVerificationUrl;
    }

    openseaBaseUrl(chainCategory = null) {

        var subPath = "";
        if (chainCategory) {
            if (chainCategory == "labs")
                if (this.getEnvironment() != "prod")
                    subPath = "/" + this.devNetworkPolygon;
                else
                    subPath = "/matic";
        }

        if (this.production && !this.dev)
            return this.mainnetOpenseaUrl + subPath;
        else
            return this.testnetOpenseaUrl + subPath;
    }

    looksRareBaseUrl(chainCategory = null) {
        return this.mainnetLooksRareUrl;
    }

    appBaseUrl() {
        if (this.production)
            return this.mainnetAppBaseUrl;
        else
            return this.testnetAppBaseUrl;
    }

    getProjectRules(projectId, chainCategory){
        var rules = [];

        this.projectConfig.forEach(c=> {
            if(c.projectId == projectId && c.chainCategory == chainCategory)
                rules.push(c);
        })
        return rules;
    }


    getProjectArticle(projectId, chainCategory){
        var info = null;
        projectId = Number(projectId)
        this.articleConfig.forEach(c=> {
            if(c.projectId == projectId && c.chainCategory == chainCategory)
                info = c
        })
        return info;
    }

    viewProjectOnOpenseaUrl(projectName, chainCategory = null) {
        if(this.chainCategory)
            this.chainCategory = chainCategory;

        var config = this.contractAddressMapping.filter(m => m.chainCategory == this.chainCategory && m.chain == this.chain && m.environment == this.getEnvironment())[0];
        return config.openseaBaseUrl.replace("TKTK", projectName);
    }

    //?filters={"attributes":[{"traitType":"d!ss|pat!on","values":["project+:+d!ss|pat!on"]}]}
    viewProjectOnLooksRareUrl(projectName, chainCategory) {
        var tokenContractAddress = this.tokenContractAddress(chainCategory);
        projectName = projectName.replace(/ /g, "+");
        return this.looksRareBaseUrl() + tokenContractAddress + `?filters={"attributes":[{"traitType":"${projectName}","values":["project+:+${projectName}", "Project+:+${projectName}"]}]}`;
    }

    public getChainFromCategory(chainCategory) {
        var mappings = this.contractAddressMapping.filter(c => c.chainCategory == chainCategory);
        return mappings[0].chain;
    }

    public getDefaultCategoryProjectMapping(projectId) {
        var mappings = this.contractCategoryProjectExplicitMapping.filter(c => c.projectId == projectId);
        if (mappings.length == 0)
            return "main";
        else
            return "genesis"
    }

    public getEnvironment() {
        if (this.dev)
            return "dev";
        else if (this.production)
            return "prod";
        else
            return "test";
    }

    public setCryptoLocation(projectId, chainCategory) {

        if (!chainCategory)
            chainCategory = this.getDefaultCategoryProjectMapping(projectId);

        this.chainCategory = chainCategory;

        if (this.chainCategory == "ava")
            this.chain = "avalanche";
        else if (this.chainCategory == "labs")
            this.chain = "polygon";
        else
            this.chain = "ethereum";

        this.projectId = projectId;
    }

    public contractProjectProviderExists(chainCategory) {
        var providers = this.contractProjectProviders.filter(cp => cp.category == chainCategory);
        if (providers.length > 0)
            return providers[0];
        else
            return null;
    }
}
